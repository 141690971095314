import React, { lazy, Suspense } from "react";

const LazyObjectNativeIntegration = lazy(
  () => import("./native-integration/object-native-integration"),
);

export function ObjectNativeIntegration(props) {
  return (
    <Suspense fallback={null}>
      <LazyObjectNativeIntegration {...props} />
    </Suspense>
  );
}

const LazyWorkflowManager = lazy(
  () => import("./native-integration/workflow-manager/workflow-manager"),
);

export function WorkflowManager(props) {
  return (
    <Suspense fallback={null}>
      <LazyWorkflowManager {...props} />
    </Suspense>
  );
}

const LazyCustomIntegrationSetupPage = lazy(
  () => import("./custom-integration/index"),
);

export function CustomIntegrationSetupPage(props) {
  return (
    <Suspense fallback={null}>
      <LazyCustomIntegrationSetupPage {...props} />
    </Suspense>
  );
}

const LazyDocumentCloudProviders = lazy(
  () => import("./setup/cloud-providers/document-cloud-providers-form"),
);

export function DocumentCloudProviders(props) {
  return (
    <Suspense fallback={null}>
      <LazyDocumentCloudProviders {...props} />
    </Suspense>
  );
}

const LazyAemDocumentComponents = lazy(() =>
  import(
    "./native-integration/components/QuarrySelectors/AemDocumentComponents"
  ).then((module) => ({ default: module.AemDocumentComponents })),
);

export function AemDocumentComponents(props) {
  return (
    <Suspense fallback={null}>
      <LazyAemDocumentComponents {...props} />
    </Suspense>
  );
}

const LazyIntegrationMappingModal = lazy(
  () => import("./native-integration/integration-mapping"),
);

export function IntegrationMappingModal(props) {
  return (
    <Suspense fallback={null}>
      <LazyIntegrationMappingModal {...props} />
    </Suspense>
  );
}

const LazyExperienceManager = lazy(
  () => import("./native-integration/experience-manager"),
);

export function ExperienceManager(props) {
  return (
    <React.Suspense fallback={null}>
      <LazyExperienceManager {...props} />
    </React.Suspense>
  );
}

const LazySharepointIntegrationSetupPage = lazy(
  () => import("./sharepoint-integration/index"),
);

export function SharepointIntegrationSetupPage(props) {
  return (
    <Suspense fallback={null}>
      <LazySharepointIntegrationSetupPage {...props} />
    </Suspense>
  );
}
